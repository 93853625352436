import { useMemo } from 'react'
import { BorrowPosition } from '../types/borrowPositionData'
import { Fraction, Percent, Token } from '@dolomite-exchange/sdk-core'
import { InterestRate, useInterestRateData } from '../types/interestRateData'
import { ChainId, ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import { Currency, CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { createFraction } from '../types/gqlTypeHelpers'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { RefreshFrequency } from '../state/chain/hooks'

export interface InterestRateRecordResponse {
  supplyInterestRate: string
  borrowInterestRate: string | null
  label: string
  timestamp: number
}

export interface InterestRateRecord {
  supplyInterestRate: Fraction
  borrowInterestRate: Fraction | undefined
  label: string
  timestamp: Date
}

export interface InterestRateSeriesResponse {
  interestRateSeries: InterestRateRecordResponse[]
}

export function useHistoricalInterestRateSeries(token: Token | undefined, chain: ChainId | undefined) {
  const url = useMemo(() => {
    if (!token || !chain) {
      return undefined
    }
    return `${DOLOMITE_API_SERVER_URL}/tokens/${chain}/interest-rates/${token.address.toLowerCase()}/series`
  }, [token, chain])
  const queryState = useGraphqlResult<InterestRateSeriesResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chain}/interest-rates/${token?.address.toLowerCase()}/series`,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  const serverInterestRates = useMemo(() => {
    if (queryState.result && queryState.result.interestRateSeries) {
      return queryState.result.interestRateSeries.reduce((memo, t) => {
        const supplyRate = createFraction(t.supplyInterestRate)
        const borrowRate = t.borrowInterestRate ? createFraction(t.borrowInterestRate) : undefined
        const timestamp = new Date(t.timestamp * 1000)
        memo.push({
          supplyInterestRate: supplyRate.multiply(100), // new Percent(supplyRate.numerator, supplyRate.denominator),
          borrowInterestRate: borrowRate ? borrowRate.multiply(100) : undefined, // new Percent(borrowRate.numerator, borrowRate.denominator),
          label: t.label,
          timestamp: timestamp,
        })
        return memo
      }, [] as InterestRateRecord[])
    }
    return undefined
  }, [queryState.result])

  return useMemo(() => {
    if (serverInterestRates) {
      return serverInterestRates
    }
    return undefined
  }, [serverInterestRates])
}

export function getStrategyInterestRate(
  supplyAmounts: CurrencyAmount<Currency>[],
  borrowAmounts: CurrencyAmount<Currency>[],
  fiatPrices: Record<string, Fraction | undefined>,
  interestRateMap: Record<string, InterestRate | undefined>,
  totalRate: boolean,
  historical?: boolean,
  includeParts?: string[] | undefined,
  newSupplyRates?: Percent[] | undefined,
  newBorrowRates?: Percent[] | undefined,
  pendleFixedRate?: Fraction,
): Percent | undefined {
  if (!supplyAmounts || !borrowAmounts || !interestRateMap || !fiatPrices) {
    return undefined
  }
  const [netSupplyInterest, totalSupplyAmount] = supplyAmounts.reduce(
    (interestAndTotal, supplyAmount) => {
      if (historical) {
        const address = supplyAmount.currency.wrapped.address
        const price = fiatPrices[address]
        const interestRate = interestRateMap[address]
        const amountUSD = price?.multiply(supplyAmount.asFraction) ?? ZERO_FRACTION
        const historicalRate = pendleFixedRate ?? interestRate?.historicalRates['30d']?.supplyInterestRate
        if (!historicalRate) return interestAndTotal
        return [interestAndTotal[0].add(amountUSD.multiply(historicalRate)), interestAndTotal[1].add(amountUSD)]
      }
      const index = supplyAmounts.findIndex(supply => supply.currency.equals(supplyAmount.currency))
      const newSupplyRate = newSupplyRates && newSupplyRates.length >= index + 1 ? newSupplyRates[index] : undefined
      const address = supplyAmount.currency.wrapped.address
      const price = fiatPrices[address]
      const amountUSD = price?.multiply(supplyAmount.asFraction) ?? ZERO_FRACTION
      const interestRate = interestRateMap[address]
      const parts = includeParts
      const outsideYield = interestRate?.outsideSupplyInterestRateParts?.reduce((prev: Percent, interestRatePart) => {
        return parts?.includes(interestRatePart.label)
          ? interestRatePart.label.includes('Pendle Fixed') && pendleFixedRate
            ? prev.add(pendleFixedRate)
            : prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
          : prev
      }, ZERO_PERCENT)
      const noRewardsSupplyInterestRate =
        interestRate &&
        interestRate.supplyInterestRate &&
        (newSupplyRate ?? interestRate.supplyInterestRate).add(outsideYield ?? ZERO_PERCENT)
      const currentRate = totalRate
        ? newSupplyRate
          ? interestRate?.totalSupplyInterestRate.subtract(interestRate?.supplyInterestRate).add(newSupplyRate)
          : interestRate?.totalSupplyInterestRate
        : noRewardsSupplyInterestRate
      if (!currentRate) {
        return interestAndTotal
      }
      return [interestAndTotal[0].add(amountUSD.multiply(currentRate)), interestAndTotal[1].add(amountUSD)]
    },
    [ZERO_FRACTION, ZERO_FRACTION],
  )
  const [netBorrowInterest, totalBorrowAmount] = borrowAmounts.reduce(
    (interestAndTotal, borrowAmount) => {
      const index = borrowAmounts.findIndex(borrow => borrow.currency.equals(borrowAmount.currency))
      const newBorrowRate = newBorrowRates && newBorrowRates.length >= index + 1 ? newBorrowRates[index] : undefined
      const address = borrowAmount.currency.wrapped.address
      const price = fiatPrices[address]
      const amountUSD = price?.multiply(borrowAmount.asFraction) ?? ZERO_FRACTION
      const parts = includeParts
      const outsideRate = interestRateMap[address]?.outsideBorrowInterestRateParts?.reduce(
        (prev: Percent, interestRatePart) => {
          return parts?.includes(interestRatePart.label)
            ? prev.add(interestRatePart.interestRate ?? ZERO_PERCENT)
            : prev
        },
        ZERO_PERCENT,
      )
      const interestRate = interestRateMap[address]
      const currentRate = newBorrowRate
        ? newBorrowRate.add(outsideRate ?? ZERO_PERCENT)
        : interestRateMap[address]?.totalBorrowInterestRate
      const historicalRate = interestRate?.historicalRates['30d'].borrowInterestRate
      const finalInterestRate = !historical ? currentRate : historicalRate
      if (!finalInterestRate) {
        return interestAndTotal
      }
      return [interestAndTotal[0].add(amountUSD.multiply(finalInterestRate)), interestAndTotal[1].add(amountUSD)]
    },
    [ZERO_FRACTION, ZERO_FRACTION],
  )
  return Percent.fromFraction(
    netSupplyInterest.subtract(netBorrowInterest).divide(totalSupplyAmount.subtract(totalBorrowAmount)),
  )
}

export function useStrategyInterestRate(
  supplyAmounts: CurrencyAmount<Currency>[],
  borrowAmounts: CurrencyAmount<Currency>[],
  fiatPrices: Record<string, Fraction | undefined>,
  interestRateMap: Record<string, InterestRate | undefined>,
  totalRate: boolean,
  historical?: boolean,
  includeParts?: string[] | undefined,
  newSupplyRates?: Percent[] | undefined,
  newBorrowRates?: Percent[] | undefined,
  pendleFixedRate?: Fraction,
): Percent | undefined {
  return useMemo(
    () =>
      getStrategyInterestRate(
        supplyAmounts,
        borrowAmounts,
        fiatPrices,
        interestRateMap,
        totalRate,
        historical,
        includeParts,
        newSupplyRates,
        newBorrowRates,
        pendleFixedRate,
      ),
    [
      supplyAmounts,
      borrowAmounts,
      fiatPrices,
      interestRateMap,
      totalRate,
      historical,
      includeParts,
      newSupplyRates,
      newBorrowRates,
      pendleFixedRate,
    ],
  )
}

export default function useNetInterestRate(
  position: BorrowPosition | undefined,
  pendleFixedRate?: Fraction,
): Percent | undefined {
  const { data: interestRateMap } = useInterestRateData()
  return useMemo(() => {
    if (!position || !interestRateMap) {
      return undefined
    }
    const [netSupplyInterest, totalSupplyAmount] = position.supplyAmounts.reduce(
      (interestAndTotal, supplyAmount) => {
        const interestRate = pendleFixedRate ?? interestRateMap[supplyAmount.token.address]?.totalSupplyInterestRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [
          interestAndTotal[0].add(supplyAmount.amountUSD.multiply(interestRate)),
          interestAndTotal[1].add(supplyAmount.amountUSD),
        ]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    const [netBorrowInterest, totalBorrowAmount] = position.borrowAmounts.reduce(
      (interestAndTotal, borrowAmount) => {
        const interestRate = interestRateMap[borrowAmount.token.address]?.totalBorrowInterestRate
        if (!interestRate) {
          return interestAndTotal
        }
        return [
          interestAndTotal[0].add(borrowAmount.amountUSD.multiply(interestRate)),
          interestAndTotal[1].add(borrowAmount.amountUSD),
        ]
      },
      [ZERO_FRACTION, ZERO_FRACTION],
    )
    return Percent.fromFraction(
      netSupplyInterest.subtract(netBorrowInterest).divide(totalSupplyAmount.subtract(totalBorrowAmount)),
    )
  }, [interestRateMap, pendleFixedRate, position])
}
