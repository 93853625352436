import styled from 'styled-components'
import { Box, Text, Button as RebassButton } from 'rebass/styled-components'

export const DEFAULT_HEIGHT = 300
export const DEFAULT_HEIGHT_BALANCES = 150

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  border-radius: 8px;
  padding: 20px 25px;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`

const Row = styled(Box)<{
  width?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`

export const RowFixed = styled(Row)<{ gap?: string; justify?: string; mobile?: boolean }>`
  width: fit-content;
  margin: ${({ gap }) => gap && `-${gap}`};
`

export const RowBetween = styled(Row)`
  justify-content: space-between;
`

export const ChartRow = styled(RowBetween)<{ marginTop?: number; large?: boolean }>`
  margin-top: ${({ marginTop }) => marginTop && `${marginTop}px`};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    row-gap: 1rem;
  `};
`

export const Wrapper = styled(Card)<{ minHeight: number; balances?: boolean }>`
  width: 100%;
  height: ${({ minHeight }) => minHeight}px;
  padding: 20px 25px;
  display: flex;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  flex-direction: column;
  > * {
    font-size: 1rem;
  }
  ${({ balances }) =>
    balances &&
    `@media screen and (max-width: 450px) {
    padding: 10px 12px;
  }`}
`

export const Button = styled(RebassButton)<{ active?: boolean }>`
  padding: 4px;
  width: fit-content;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  outline: none;
  border: 1px solid transparent;
  color: ${({ active, theme }) => (active ? theme.text1 : theme.text2)};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  min-width: 36px;
  font-size: 12px;
  background-color: ${({ active, theme }) => (active ? theme.bg2 : theme.bg1)};

  :hover {
    opacity: 0.6;
  }

  &:disabled {
    cursor: auto;
  }
  > * {
    user-select: none;
  }
`

export const ChartLabel = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 4px;
`

export const ChartText = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.text2}
  height: 14px !important;
`

export const ChartTextLarge = styled(Text)<{ small?: boolean }>`
  font-weight: 600;
  font-size: 32px;
  color: ${({ theme }) => theme.text1};

  ${({ small }) =>
    small &&
    `@media screen and (max-width: 500px) {
      font-size: 20px;
    }`}
`

export const ChartTextMedium = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.text3};
`

export const ChartValue = styled.span`
  font-variant-numeric: tabular-nums;
`
