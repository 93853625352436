import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/common/PageTitle'
import {
  useCurrentDayTokenHourlyData,
  useCurrentWeekTokenHourlyData,
  usePreviousDayTokenHourlyData,
  usePreviousWeekTokenHourlyData,
} from '../../types/tokenHourData'
import { useTokenDayDataData, useTokenYearData } from '../../types/tokenDayData'
import { StatLoader, unixToDate } from './index'
import BarChart from '../../components/Stats/Charts/BarChart'
import LineChart from '../../components/Stats/Charts/LineChart'
import { useActiveWeb3React } from '../../hooks'
import {
  Button,
  ChartLabel,
  ChartText,
  ChartTextLarge,
  ChartValue,
  RowBetween,
  RowFixed,
} from '../../components/Stats/Charts/styled'
import { InterestRate, useHistoricalInterestRateDataByToken, useInterestRateData } from '../../types/interestRateData'
import { useTopPairData } from '../../types/topPairData'
import { useTradeDataByToken } from '../../types/tradeData'
import { MarketRiskInfo, useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'
import { DolomiteMarginData, useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { useLiquidationDataByToken } from '../../types/liquidationData'
import { useTransfersByToken } from '../../types/depositWithdrawalData'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Rounding, Token } from '@dolomite-exchange/v2-sdk'
import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import {
  useDefaultFiatValueWithLoadingIndicator,
  useFiatPricesPreviousDay,
  useFiatPricesWithLoadingIndicator,
} from '../../hooks/useFiatValue'
import { useDolomiteMarginContract } from '../../hooks/useContract'
import useTheme from '../../hooks/useTheme'
import { maxBy, minBy, sumFractions } from '../../utils/numberOperations'
import {
  ChainId,
  FORMATTER,
  ONE_FRACTION,
  USDC,
  WETH,
  ZERO_ADDRESS,
  ZERO_FRACTION,
  ZERO_PERCENT,
} from '../../constants'
import JSBI from 'jsbi'
import { ExternalLink, ExternalLinkIcon } from '../../theme'
import { getEtherscanLink } from '../../utils'
import calculatePercentageChange from '../../utils/calculatePercentageChange'
import { useToken } from '../../hooks/Tokens'
import calculateUtilization from '../../utils/calculateUtilization'
import calculateLiquidationReward from '../../utils/calculateLiquidationReward'
import calculateLiquidationThreshold from '../../utils/calculateLiquidationThreshold'
import getInterestRateAtUtilization from '../../utils/getInterestRateAtUtilization'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import getLogoOrDefault from '../../components/common/TokenLogos'
import { tryParseAmountWithNoCurrency } from '../../state/trade/hooks'
import { useTokenBalances } from '../../state/wallet/hooks'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useSpecialAsset } from '../../constants/isolation/special-assets'
import { formatAmount } from '../../utils/formatAmount'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { YieldTooltip } from '../../components/Balances/BalancesPanel'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { isBerachain, NETWORK_LABELS, NETWORK_LOGOS, NETWORKS } from '../../constants/chainId'
import calculateAprToApy from '../../utils/calculateAprToApy'
import { useDGmxToken } from '../../hooks/gmx/useGmxDolomiteProxyProtocol'
import Liquidations from '../../components/Stats/Tables/Liquidations'
import DepositTable from '../../components/Stats/Tables/TransferTable'
import TradeTable from '../../components/Stats/Tables/TradeTable'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistoricalInterestRateSeries } from '../../hooks/useNetInterestRate'
import { Box } from 'rebass/styled-components'

enum ChartTypes {
  VolumeDaily,
  SupplyLiquidity,
  BorrowLiquidity,
}

enum ChartUnit {
  USD,
  TOKEN,
}

enum InterestChartType {
  Price,
  InterestRate,
  Utilization,
  Supply,
  Borrow,
}

const TRADING = 0
const TRANSFERS = 1
const LIQUIDATIONS = 2

const TokenStatsWrapper = styled.div`
  margin: 25px auto 0;
  width: calc(90% - 80px);
  max-width: 1400px;

  @media screen and (max-width: 730px) {
    width: calc(100% - 40px);
  }
`

const Header = styled.div`
  @media screen and (max-width: 500px) {
    float: none;
    width: 100%;
    text-align: center;
  }
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 500;
  width: fit-content;
  float: left;

  @media screen and (max-width: 500px) {
    float: none;
    width: auto;
    text-align: center;
  }
`

const ChartWrapper = styled.div`
  width: 68%;
  max-width: calc(100% - 300px);
  display: inline-block;
  vertical-align: top;
  background-color: ${({ theme }) => theme.bg2}
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  @media screen and (max-width: 880px) {
    width: 100%;
  }
`

export const BackButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: 5000px) {
    display: block;
    width: 100%;
    text-align: left;
  }
`

export const BackButton = styled.div`
  width: fit-content;
  display: inline-block;
  cursor: pointer;

  > div {
    transform: rotate(180deg);
    display: inline-block;
  }
`

const Overview = styled.div`
  width: 100%;
  margin: 15px 0;
  height: 40px;

  @media screen and (max-width: 500px) {
    height: 115px;
    margin: 0;
  }
`

const Green = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.green1};
  margin-top: -4px;
`

const Red = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.red1};
  margin-top: -4px;
`

const PriceTitle = styled.div`
  font-size: 23px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;

  ${Red},
  ${Green} {
    margin-top: -4px;
    font-size: 12px;

    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 500px) {
    margin: 0;
    font-size: 25px;
  }
`

export const Change = ({ value }: { value: Fraction }) => {
  const positive = value.greaterThanOrEqual('0')
  if (positive) {
    return <Green>+{value.toFixed(2, FORMATTER)}%</Green>
  } else {
    return <Red>-{value.toFixed(2, FORMATTER).replace('-', '')}%</Red>
  }
}

const Links = styled.div`
  display: inline-block;
  float: right;
  margin-bottom: 15px;
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.text2} !important;
  font-size: 15px;
  transition: transform 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin-left: 10px;
  float: right;

  &:hover {
    background-color: ${({ theme }) => theme.bg2} !important;
  }
`

const TokenDataRow = styled(RowBetween)`
  margin-bottom: 30px;
  @media screen and (max-width: 880px) {
    flex-direction: column;
    margin-bottom: 0;

    > div {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
`

const TokenData = styled.div<{ height: number }>`
  width: 30%;
  position: relative;
  min-width: 280px;
  height: ${({ height }) => height}px;
  display: inline-block;
  vertical-align: top;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 25px 30px;
  border-radius: 8px;
`

const TokenTitle = styled.div`
  color: ${({ theme }) => theme.text2};
`

const TokenValue = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 25px;
`

const OutsideRate = styled.span`
  color: ${({ theme }) => theme.blue1};
`

const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  display: inline !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: 3px;
  margin-bottom: -1px;

  path {
    fill: ${({ theme }) => theme.blue2};
  }
`

const TokenAmount = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 16px;
  margin-top: -4px;
  margin-bottom: 2px;
`

const Faded = styled.span`
  color: ${({ theme }) => theme.text3};
`

const TokenLogoWrapper = styled.div<{ small: boolean }>`
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  height: ${({ small }) => (small ? '18px' : '28px')};
  margin-top: ${({ small }) => (small ? '-4px' : '4px')};

  img {
    height: 100%;
  }
`

const Trades = styled.div`
  width: 100%;
  margin: 10px 0 30px;

  @media screen and (max-width: 880px) {
    margin-bottom: 90px;
  }
`

const LinkWrapper = styled.div`
  width: fit-content;
  margin: 5px auto 0;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;

  > a {
    color: ${({ theme }) => theme.text3} !important;

    svg {
      stroke: ${({ theme }) => theme.text3} !important;
      opacity: 1 !important;
    }

    > a {
      opacity: 1 !important;
    }

    :hover {
      color: ${({ theme }) => theme.text1} !important;

      svg {
        stroke: ${({ theme }) => theme.text1} !important;
      }
    }
  }
`

export const ChartButton = styled(Button)<{ large?: boolean }>`
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  @media screen and (max-width: 1000px) {
    ${({ large }) =>
      large &&
      `
      max-width: 70px;
      min-width: 57px;
    `}
    margin-left: 4px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`

const ChartButtonSmall = styled(ChartButton)`
  width: auto;
`

const ChartRow = styled(RowFixed)`
  margin-top: 2px;
  text-align: right;
`

const ChartRowMobile = styled.div`
  width: 270px;
  margin-left: -5px;
`

const RowWrapper = styled.div``

const RowWrapperHideOnSmall = styled.div`
  @media screen and (max-width: 425px) {
    display: none;
  }
`

const RowWrapperSmall = styled.div`
  display: inline-block;
  vertical-align: top;
`

const RowWrapperHideOnLarge = styled.div`
  width: 100%;
  display: block;
  @media screen and (min-width: 426px) {
    display: none;
  }
`

const SmallValue = styled.div`
  font-size: 18px;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const NetworkRow = styled.div`
  width: auto;
  float: right;
  margin-top: 20px;
  font-size: 18px;
  display: inline-block;

  @media screen and (max-width: 500px) {
    margin-top: 0;
    float: none;
    margin-bottom: 5px;
    font-size: 15px;
  }
  img {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    margin-bottom: -6px;
    @media screen and (max-width: 500px) {
      width: 20px;
      height: 20px;
      margin-right: 2px;
      margin-bottom: -4px;
    }
  }
`

const InlineBetaLabel = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 1.5px 3px;
  font-weight: 700;
  margin-left: -2px;
  margin-top: 6px;
  vertical-align: top;
  display: inline-block;
  opacity: 0.7;
`

const RightTop = styled.div`
  display: inline-block;
  float: right;
  @media screen and (max-width: 500px) {
    float: none;
    display: block;
    margin-top: 1px;
    text-align: center;
  }
`

const TokenLogo = ({ symbol }: { symbol: string | undefined }) => {
  return symbol !== undefined ? (
    <TokenLogoWrapper small={false}>
      <img src={getLogoOrDefault(symbol || '')} alt={`${symbol} logo`} />
    </TokenLogoWrapper>
  ) : null
}

const AllowableTokenLogo = ({ token }: { token: Token }) => {
  const cleanSymbol = cleanCurrencySymbol(token)
  return cleanSymbol !== undefined ? (
    <StyledTooltip title={cleanSymbol} placement={'bottom'} arrow={true}>
      <TokenLogoWrapper small={true}>
        <img src={getLogoOrDefault(cleanSymbol)} alt={`${cleanSymbol} logo`} />
      </TokenLogoWrapper>
    </StyledTooltip>
  ) : null
}

export function getInterestRateDataForUtilization(
  marketRiskInfo: MarketRiskInfo | undefined,
  interestRateObj: InterestRate | undefined,
  chainId: ChainId,
  dolomiteMargin: DolomiteMarginData | undefined,
) {
  if (marketRiskInfo && interestRateObj) {
    if (marketRiskInfo.isBorrowingDisabled) {
      return [...Array(101)].map((_, i) => ({
        time: i,
        value: 0,
      }))
    }

    return [...Array(101)].map((_, i) => {
      return {
        time: i,
        value: parseFloat(
          getInterestRateAtUtilization(
            new Percent(i, 100),
            chainId,
            JSBI.BigInt(marketRiskInfo.marketId),
            interestRateObj,
            dolomiteMargin,
            true,
          ).borrowInterestRate.toFixed(2),
        ),
      }
    })
  } else {
    return []
  }
}

function useEffectiveTokenForTrades(token: Token | undefined, chainId: ChainId): Token | undefined {
  const dGmxToken = useDGmxToken()
  const dGmxSpecialAsset = useSpecialAsset(dGmxToken)
  return dGmxToken && token?.equals(dGmxToken)
    ? dGmxSpecialAsset?.isolationModeInfo?.getUnderlyingToken(chainId)
    : token
}

export default function TokenStats() {
  const { t } = useTranslation()
  const { chain, tokenId } = useParams<{ chain: string; tokenId: string }>()
  const { chainId } = useActiveWeb3React()
  const urlChain = parseInt(chain) as ChainId
  const token = useToken(tokenId, urlChain)
  // const currentChainToken = useToken(tokenId, urlChain)

  const history = useHistory()
  const [showYieldAsApr] = useShowYieldAsApr()
  const { data: currentDayTokenHourlyData } = useCurrentDayTokenHourlyData(token, urlChain)
  const { data: previousDayTokenHourlyData } = usePreviousDayTokenHourlyData(token, urlChain)
  const { data: currentWeekTokenHourlyData } = useCurrentWeekTokenHourlyData(token, urlChain)
  const { data: previousWeekTokenHourlyData } = usePreviousWeekTokenHourlyData(token, urlChain)
  const { data: tokenDataAll, loading: tokenDataLoading } = useTokenDayDataData(token, urlChain)
  // const { data: historicalInterestRateData } = useHistoricalInterestRateDataByToken(token, urlChain)
  const interestRateHistory = useHistoricalInterestRateSeries(token, urlChain)
  const { data: interestRateMap } = useInterestRateData(urlChain)
  const { data: dolomiteMargin } = useDolomiteMarginData(urlChain)
  const tokenTvlMap = useDolomiteMarginTokenTvlData(urlChain)
  const { data: marketRiskInfoMap } = useMarketRiskInfoData(urlChain)
  const dolomiteMarginContract = useDolomiteMarginContract(urlChain)
  const protocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, [token])
  const { data: priceHistory } = useTokenYearData(token, urlChain)
  const selectedChain = useMemo(() => urlChain ?? chainId, [chainId, urlChain])
  const effectiveTokenForTrades = useEffectiveTokenForTrades(token, selectedChain)
  const interestRateObj = useMemo(() => interestRateMap?.[token?.address ?? ''], [interestRateMap, token])
  const totalSupplyRate = useMemo(
    () => interestRateMap?.[token?.address ?? '']?.totalSupplyInterestRate ?? ZERO_PERCENT,
    [interestRateMap, token],
  )
  const borrowRate = useMemo(() => interestRateMap?.[token?.address ?? '']?.borrowInterestRate ?? ZERO_PERCENT, [
    interestRateMap,
    token,
  ])
  const lendingRate = useMemo(() => interestRateMap?.[token?.address ?? '']?.supplyInterestRate ?? ZERO_PERCENT, [
    interestRateMap,
    token,
  ])
  const outsideInterestRateParts = useMemo(
    () => interestRateMap?.[token?.address ?? '']?.outsideSupplyInterestRateParts ?? undefined,
    [interestRateMap, token],
  )
  const totalBorrowRate = useMemo(
    () => interestRateMap?.[token?.address ?? '']?.totalBorrowInterestRate ?? ZERO_PERCENT,
    [interestRateMap, token],
  )
  const borrowInterestRateParts = useMemo(
    () => interestRateMap?.[token?.address ?? '']?.outsideBorrowInterestRateParts ?? undefined,
    [interestRateMap, token],
  )
  const [chartDataType, setChartDataType] = useState(ChartTypes.SupplyLiquidity)
  const [chartUnit, setCharUnit] = useState(ChartUnit.USD)
  const [loading, setLoading] = useState<boolean>(true)
  const [mainChartLabel, setMainChartLabel] = useState<string | undefined>()
  const [mainChartValue, setMainChartValue] = useState<number | undefined>()
  const [interestValue, setInterestValue] = useState<number | undefined>()
  const [interestChartType, setInterestChartType] = useState(InterestChartType.Price)
  const [loadedInterestRateData, setLoadedInterestRateData] = useState<any[] | undefined>(undefined)
  const theme = useTheme()
  const { data: rawTrades, loading: isTradesLoading } = useTradeDataByToken(effectiveTokenForTrades?.address, urlChain)
  const trades = useMemo(() => {
    if (!token || !effectiveTokenForTrades) {
      return rawTrades
    } else if (token.equals(effectiveTokenForTrades)) {
      return rawTrades
    }
    return rawTrades.filter(t => {
      return !(
        (t.primaryDeltaWei.currency.equals(effectiveTokenForTrades) && t.secondaryDeltaWei.currency.equals(token)) ||
        (t.primaryDeltaWei.currency.equals(token) && t.secondaryDeltaWei.currency.equals(effectiveTokenForTrades))
      )
    })
  }, [rawTrades, token, effectiveTokenForTrades])
  const { data: liquidationData, loading: isLiquidationsLoading } = useLiquidationDataByToken(token?.address, urlChain)
  const { data: transferData, loading: isTransfersLoading } = useTransfersByToken(token?.address, urlChain)
  const [selectedTab, setSelectedTab] = useState<number>(TRADING)
  const specialAsset = useSpecialAsset(token, urlChain)

  const options = [t('trades'), t('transfers'), t('liquidations')]
  const allowableCollateralTokens = useMemo(
    () => specialAsset?.isolationModeInfo?.allowableCollateralTokens?.(selectedChain),
    [selectedChain, specialAsset],
  )
  const allowableDebtTokens = useMemo(() => specialAsset?.isolationModeInfo?.allowableDebtTokens?.(selectedChain), [
    selectedChain,
    specialAsset,
  ])
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    })
  useEffect(() => {
    window.onresize = updateSize
    return () => {
      window.onresize = null
    }
  }, [])

  const protocalBalance = useMemo(() => protocolBalanceInfoMap[token?.address ?? ZERO_ADDRESS], [
    protocolBalanceInfoMap,
    token,
  ])

  useEffect(() => {
    loading && !tokenDataLoading && setLoading(false)
  }, [loading, tokenDataLoading, tokenDataAll])

  const symbol = useMemo(() => cleanCurrencySymbol(token), [token])

  const [fiatValue] = useDefaultFiatValueWithLoadingIndicator(token)

  const openPriceUSD = useMemo(() => {
    return minBy(currentDayTokenHourlyData ?? [], data => new Fraction(data.hourStartUnix.getTime()))?.openPriceUSD
  }, [currentDayTokenHourlyData])

  const closePriceUSD = useMemo(() => {
    return maxBy(currentDayTokenHourlyData ?? [], data => new Fraction(data.hourStartUnix.getTime()))?.closePriceUSD
  }, [currentDayTokenHourlyData])

  const [fiatPricePreviousDay] = useFiatPricesPreviousDay()
  const previousPriceData = fiatPricePreviousDay[token?.address ?? '']
  const [priceBalanceMap] = useFiatPricesWithLoadingIndicator(token ? [token] : undefined)
  const priceData = priceBalanceMap[token?.address ?? '']
  const percentageChange = useMemo(() => {
    if (!previousPriceData || !priceData) return ZERO_FRACTION
    return calculatePercentageChange(previousPriceData, priceData ?? ZERO_FRACTION)
  }, [previousPriceData, priceData])

  /*const percentageChange = useMemo(() => {
    return openPriceUSD && closePriceUSD ? calculatePercentageChange(openPriceUSD, closePriceUSD) : ZERO_FRACTION
  }, [openPriceUSD, closePriceUSD])*/
  const volumeCurrentDayUSD = useMemo(() => {
    return sumFractions(currentDayTokenHourlyData ?? [], data => data.hourlyTradeVolumeUSD)
  }, [currentDayTokenHourlyData])

  const volumeCurrentDay = useMemo(() => {
    return volumeCurrentDayUSD.divide(fiatValue ?? ONE_FRACTION)
  }, [volumeCurrentDayUSD, fiatValue])

  const volumePreviousDay = useMemo(() => {
    return sumFractions(previousDayTokenHourlyData ?? [], data => data.hourlyTradeVolumeUSD)
  }, [previousDayTokenHourlyData])

  const percentageChangeDay = useMemo(() => {
    return volumePreviousDay && volumeCurrentDayUSD
      ? calculatePercentageChange(volumePreviousDay, volumeCurrentDayUSD)
      : ZERO_FRACTION
  }, [volumePreviousDay, volumeCurrentDayUSD])

  const [currentTVLUSD, , currentTVLToken, previousTVLToken] = useMemo(() => {
    if (!tokenDataAll || !fiatValue) {
      return [ZERO_FRACTION, ZERO_FRACTION, ZERO_FRACTION, ZERO_FRACTION]
    } else {
      return [
        tokenDataAll[tokenDataAll.length - 1]?.supplyLiquidityToken.asFraction.multiply(fiatValue),
        tokenDataAll[tokenDataAll.length - 2]?.supplyLiquidityUSD,
        tokenDataAll[tokenDataAll.length - 1]?.supplyLiquidityToken,
        tokenDataAll[tokenDataAll.length - 2]?.supplyLiquidityToken,
      ]
    }
  }, [fiatValue, tokenDataAll])

  // This is unused in favor of # of tokens for now
  // const percentageChangeTVLUSD = useMemo(() => {
  //   return previousTVLUSD && currentTVLUSD ? calculatePercentageChange(previousTVLUSD, currentTVLUSD) : ZERO_FRACTION
  // }, [previousTVLUSD, currentTVLUSD])

  const percentageChangeTVLToken = useMemo(() => {
    return previousTVLToken && currentTVLToken
      ? calculatePercentageChange(previousTVLToken, currentTVLToken)
      : ZERO_FRACTION
  }, [previousTVLToken, currentTVLToken])

  const volumeWeek = useMemo(() => {
    return sumFractions(currentWeekTokenHourlyData ?? [], data => data.hourlyTradeVolumeUSD)
  }, [currentWeekTokenHourlyData])

  const previousVolumeWeek = useMemo(() => {
    return sumFractions(previousWeekTokenHourlyData ?? [], data => data.hourlyTradeVolumeUSD)
  }, [previousWeekTokenHourlyData])

  const percentageChangeWeeklyVolume = useMemo(() => {
    return previousVolumeWeek && volumeWeek ? calculatePercentageChange(previousVolumeWeek, volumeWeek) : ZERO_FRACTION
  }, [previousVolumeWeek, volumeWeek])

  const volumeData = useMemo(() => {
    if (tokenDataAll) {
      return tokenDataAll.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.dailyTradeVolumeToken.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [tokenDataAll])

  const volumeDataUSD = useMemo(() => {
    if (tokenDataAll) {
      return tokenDataAll.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.dailyTradeVolumeUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [tokenDataAll])

  const borrowData = useMemo(() => {
    if (tokenDataAll) {
      return tokenDataAll.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.borrowLiquidityToken.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [tokenDataAll])

  const borrowDataUSD = useMemo(() => {
    if (tokenDataAll) {
      return tokenDataAll.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.borrowLiquidityUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [tokenDataAll])

  const supplyData = useMemo(() => {
    if (tokenDataAll) {
      return tokenDataAll.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.supplyLiquidityToken.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [tokenDataAll])

  const supplyDataUSD = useMemo(() => {
    if (tokenDataAll) {
      return tokenDataAll.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.supplyLiquidityUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [tokenDataAll])

  const supplyTokens = useMemo(() => {
    const supplyLiquidity = tokenDataAll
      ? tokenDataAll[tokenDataAll.length - 1]?.supplyLiquidityToken ?? ZERO_FRACTION
      : ZERO_FRACTION
    return formatAmount(supplyLiquidity, undefined, true, '0.00')
  }, [tokenDataAll])

  const currentMainChartValue = useMemo(() => {
    return chartDataType === ChartTypes.VolumeDaily
      ? chartUnit === ChartUnit.USD
        ? parseFloat(volumeCurrentDayUSD.toFixed(2))
        : parseFloat(volumeCurrentDay.toFixed(2))
      : chartDataType === ChartTypes.SupplyLiquidity
      ? chartUnit === ChartUnit.USD
        ? parseFloat(tokenDataAll?.[tokenDataAll?.length - 1]?.supplyLiquidityUSD?.toFixed(2) ?? '0')
        : parseFloat(tokenDataAll?.[tokenDataAll?.length - 1]?.supplyLiquidityToken?.toFixed(2) ?? '0')
      : chartUnit === ChartUnit.USD
      ? parseFloat(tokenDataAll?.[tokenDataAll?.length - 1]?.borrowLiquidityUSD?.toFixed(2) ?? '0')
      : parseFloat(tokenDataAll?.[tokenDataAll?.length - 1]?.borrowLiquidityToken?.toFixed(2) ?? '0')
  }, [chartDataType, tokenDataAll, volumeCurrentDayUSD, volumeCurrentDay, chartUnit])

  const currentMainChartLabel = useMemo(() => {
    if (chartDataType === ChartTypes.VolumeDaily) {
      return `${t('volume')}`
    } else if (chartDataType === ChartTypes.SupplyLiquidity) {
      return `${t('supplyLiquidity')}`
    } else {
      return `${t('borrowLiquidity')}`
    }
  }, [chartDataType, t])

  useEffect(() => {
    setMainChartLabel(currentMainChartLabel)
  }, [currentMainChartLabel, t])

  const changeChart = useCallback((type: number, unit?: true) => {
    setMainChartValue(undefined)
    if (unit) setCharUnit(type)
    else setChartDataType(type)
  }, [])

  const marketRiskInfo = useMemo(() => {
    if (marketRiskInfoMap) return marketRiskInfoMap[token?.address ?? '']
    else return undefined
  }, [marketRiskInfoMap, token?.address])
  const borrowingDisabled = marketRiskInfo?.isBorrowingDisabled
  const borrowCap = marketRiskInfo?.borrowMaxWei
  const interestRateData = useMemo(() => {
    if (interestChartType === InterestChartType.Price) {
      return priceHistory?.map(price => ({
        time: unixToDate(price.dayStartUnix),
        value: parseFloat(price.openPriceUSD.toFixed(2)),
      }))
    } else if (interestChartType === InterestChartType.Utilization) {
      return getInterestRateDataForUtilization(marketRiskInfo, interestRateObj, selectedChain, dolomiteMargin)
    } else if (interestChartType === InterestChartType.Supply) {
      // if (chainId !== urlChain) {
      const historicalSupplyRates =
        interestRateHistory?.map(interestRate => ({
          time: interestRate.timestamp,
          value: parseFloat(interestRate.supplyInterestRate.toFixed(2)),
        })) ?? []
      return historicalSupplyRates
      // }
      // const historicalSupplyRates =
      //   historicalInterestRateData?.map(interestRate => ({
      //     time: unixToDate(interestRate.blockTimestamp),
      //     value: parseFloat(interestRate.supplyInterestRate.toFixed(2)),
      //   })) ?? []
      // if (interestRateObj) {
      //   historicalSupplyRates.push({
      //     time: unixToDate(Math.floor(Date.now() / 1000)),
      //     value: parseFloat(interestRateObj.supplyInterestRate.toFixed(2)),
      //   })
      // }
      // return historicalSupplyRates
    } else if (interestChartType === InterestChartType.Borrow) {
      // if (chainId !== urlChain) {
      const historicalBorrowRates =
        interestRateHistory?.map(interestRate => ({
          time: interestRate.timestamp,
          value: parseFloat(interestRate.borrowInterestRate?.toFixed(2) ?? '0'),
        })) ?? []
      return historicalBorrowRates
      // }
      // const historicalBorrowRates =
      //   historicalInterestRateData?.map(interestRate => ({
      //     time: unixToDate(interestRate.blockTimestamp),
      //     value: parseFloat(interestRate.borrowInterestRate.toFixed(2)),
      //   })) ?? []
      // if (interestRateObj) {
      //   historicalBorrowRates.push({
      //     time: unixToDate(Math.floor(Date.now() / 1000)),
      //     value: parseFloat(interestRateObj.borrowInterestRate.toFixed(2)),
      //   })
      // }
      // return historicalBorrowRates
    } else {
      console.error('Invalid chart type: ', interestChartType)
      return []
    }
  }, [
    selectedChain,
    interestRateHistory,
    priceHistory,
    // chainId,
    // urlChain,
    dolomiteMargin,
    // historicalInterestRateData,
    interestChartType,
    interestRateObj,
    marketRiskInfo,
  ])
  useEffect(() => {
    interestRateData && interestRateData.length > 1 && setLoadedInterestRateData(interestRateData)
  }, [interestRateData])
  const changeInterestChart = useCallback(
    (newInterestType: InterestChartType) => {
      if (newInterestType === InterestChartType.Utilization || newInterestType === InterestChartType.Borrow) {
        setInterestValue(parseFloat(interestRateObj?.borrowInterestRate.toFixed(2) ?? '0'))
      } else if (newInterestType === InterestChartType.Supply) {
        setInterestValue(parseFloat(interestRateObj?.supplyInterestRate.toFixed(2) ?? '0'))
      } else if (newInterestType === InterestChartType.Price) {
        setInterestValue(parseFloat(openPriceUSD?.toFixed(2) ?? '0'))
      }
      setInterestChartType(newInterestType)
    },
    [interestRateObj, openPriceUSD],
  )

  const liquidityData = useMemo(() => {
    return chartDataType === ChartTypes.SupplyLiquidity
      ? chartUnit === ChartUnit.USD
        ? supplyDataUSD
        : supplyData
      : chartUnit === ChartUnit.USD
      ? borrowDataUSD
      : borrowData
  }, [chartDataType, supplyData, borrowData, chartUnit, supplyDataUSD, borrowDataUSD])

  const liquidationThreshold = useMemo(
    () => calculateLiquidationThreshold(dolomiteMargin?.minCollateralization, marketRiskInfo?.marginPremium),
    [dolomiteMargin, marketRiskInfo],
  )
  const liquidationReward = useMemo(
    () => calculateLiquidationReward(dolomiteMargin?.liquidationReward, marketRiskInfo?.liquidationRewardPremium),
    [dolomiteMargin, marketRiskInfo],
  )
  const oracleAddress = marketRiskInfo?.oracleAddress ?? ZERO_ADDRESS
  const utilizationDisplay = useMemo(() => {
    if (token) {
      const supplyTvl = tokenTvlMap[token.address]?.supplyLiquidity.asFraction ?? ZERO_FRACTION
      const borrowTvl = tokenTvlMap[token.address]?.borrowLiquidity.asFraction ?? ZERO_FRACTION
      return calculateUtilization(supplyTvl, borrowTvl)
    } else {
      return ZERO_FRACTION
    }
  }, [tokenTvlMap, token])

  const currentInterestLabel = useMemo(() => {
    return interestChartType === InterestChartType.Utilization
      ? `${utilizationDisplay.toFixed(2)}%`
      : interestChartType === InterestChartType.Price
      ? `${t('currentPrice')}`
      : interestChartType === InterestChartType.Supply
      ? `${t('currentSupplyInterestRate')}`
      : `${t('currentBorrowInterestRate')}`
  }, [utilizationDisplay, interestChartType, t])
  const [interestLabel, setInterestLabel] = useState<string | undefined>(
    interestChartType === InterestChartType.Price ? `${currentInterestLabel}` : `${t('utilizationRate')}`,
  )

  const currentInterestValue = useMemo(() => {
    return interestChartType === InterestChartType.Utilization
      ? parseFloat(borrowRate.toFixed(2))
      : interestChartType === InterestChartType.Price
      ? parseFloat(closePriceUSD?.toFixed(2) ?? '0')
      : interestChartType === InterestChartType.Supply
      ? parseFloat(lendingRate.toFixed(2))
      : parseFloat(borrowRate.toFixed(2))
  }, [interestChartType, borrowRate, lendingRate, closePriceUSD])
  const topHeight = useMemo(() => {
    if (borrowingDisabled === undefined) return 400
    return borrowingDisabled ? (specialAsset?.isIsolationMode ? 505 : 400) : 460
  }, [borrowingDisabled, specialAsset])

  const bottomHeight = useMemo(() => {
    if (borrowCap === undefined) return 325
    return 395
  }, [borrowCap])
  const chartHeight = useMemo(() => (size.x > 500 ? topHeight : 325), [size.x, topHeight])
  const chartHeightBottom = useMemo(() => (size.x > 500 ? bottomHeight : 325), [size.x, bottomHeight])
  const networkLabel = NETWORK_LABELS[urlChain]
  const networkLogo = NETWORK_LOGOS[urlChain]
  return (
    <TokenStatsWrapper>
      <PageTitle title={isBerachain(chainId) ? `Bolomite | ${symbol} Stats` : `Dolomite | ${symbol} Stats`} />
      <BackButtonWrapper>
        <BackButton onClick={() => history.push(`/`)}>
          <div>➔</div>
          Back
        </BackButton>
      </BackButtonWrapper>
      <Overview>
        <StatLoader
          data={
            <Header>
              <Title>
                <TokenLogo symbol={symbol} />
                {cleanCurrencyName(token)}
                <Faded>{` (${symbol})`}</Faded>
              </Title>
              <PriceTitle>
                ${fiatValue?.toFixed(2, FORMATTER)}
                <Change value={percentageChange} />
              </PriceTitle>
              <RightTop>
                <NetworkRow key={chainId}>
                  <img alt={`${networkLabel} logo`} src={networkLogo} />
                  {networkLabel}
                  {selectedChain === ChainId.POLYGON_ZKEVM && <InlineBetaLabel>BETA</InlineBetaLabel>}
                </NetworkRow>
              </RightTop>
            </Header>
          }
          loading={loading}
          x={300}
          y={30}
          top={5}
          height={60}
        />
      </Overview>
      <TokenDataRow>
        <TokenData height={topHeight}>
          <TokenTitle>{t('supplyLiquidity')}</TokenTitle>
          <StatLoader
            data={
              <>
                <TokenValue>{formatAmount(currentTVLUSD, 2, true, '$0.00', true)}</TokenValue>
                <TokenAmount>{`${supplyTokens} ${symbol}`}</TokenAmount>
                <Change value={percentageChangeTVLToken} />
              </>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={76}
          />
          {!borrowingDisabled ? (
            <>
              <TokenTitle>{t('borrowInterestRate')}</TokenTitle>
              <StatLoader
                data={
                  <TokenValue>
                    {borrowInterestRateParts && borrowInterestRateParts.length > 0 ? (
                      <StyledTooltip
                        title={
                          <YieldTooltip
                            interestRateParts={borrowInterestRateParts}
                            lendingYield={borrowRate}
                            totalSupplyYield={totalBorrowRate}
                            showYieldAsApr={showYieldAsApr}
                            isBorrowRate={true}
                          />
                        }
                        placement={'top'}
                        arrow={true}
                      >
                        <OutsideRate>
                          {formatAmount(totalBorrowRate, 2, true, '0%')}
                          <StyledAutoAwesomeIcon />
                        </OutsideRate>
                      </StyledTooltip>
                    ) : (
                      <>{formatAmount(borrowRate, 2, true, '0%')}</>
                    )}
                  </TokenValue>
                }
                loading={loading}
                x={100}
                y={22}
                top={6}
                height={42}
              />
            </>
          ) : null}
          <TokenTitle>{t('supplyInterestRate')}</TokenTitle>
          <StatLoader
            data={
              <TokenValue>
                {outsideInterestRateParts && outsideInterestRateParts.length > 0 ? (
                  <StyledTooltip
                    title={
                      <YieldTooltip
                        interestRateParts={outsideInterestRateParts}
                        lendingYield={lendingRate}
                        totalSupplyYield={totalSupplyRate}
                        showYieldAsApr={showYieldAsApr}
                        isBorrowRate={false}
                      />
                    }
                    placement={'top'}
                    arrow={true}
                  >
                    <OutsideRate>
                      {formatAmount(totalSupplyRate, 2, true, '0%')}
                      <StyledAutoAwesomeIcon />
                    </OutsideRate>
                  </StyledTooltip>
                ) : (
                  <>{formatAmount(totalSupplyRate, 2, true, '0%')}</>
                )}
              </TokenValue>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={42}
          />
          <TokenTitle>{t('volume')}</TokenTitle>
          <StatLoader
            data={
              <>
                <TokenValue>{formatAmount(volumeCurrentDayUSD, 2, true, '$0.00', true)}</TokenValue>
                <TokenAmount>{`${formatAmount(volumeCurrentDay)} ${symbol}`}</TokenAmount>
                <Change value={percentageChangeDay} />
              </>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={76}
          />
          <TokenTitle>{t('volumeWeek')}</TokenTitle>
          <StatLoader
            data={
              <>
                <TokenValue>{formatAmount(volumeWeek, 2, true, '$0.00', true)}</TokenValue>
                <TokenAmount>{`${formatAmount(volumeWeek.divide(fiatValue ?? ONE_FRACTION))} ${symbol}`}</TokenAmount>
                <Change value={percentageChangeWeeklyVolume} />
              </>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={76}
          />
          {specialAsset?.isIsolationMode ? (
            <>
              <TokenTitle>{t('collateralTokens')}</TokenTitle>
              <TokenValue>
                {allowableCollateralTokens?.length === 0 ? (
                  <SmallValue>All</SmallValue>
                ) : (
                  <>
                    {allowableCollateralTokens?.map((token, i) => (
                      <AllowableTokenLogo token={token} key={token.address} />
                    ))}
                  </>
                )}
              </TokenValue>
              <TokenTitle>{t('debtTokens')}</TokenTitle>
              <TokenValue>
                {allowableDebtTokens?.length === 0 ? (
                  <SmallValue>All</SmallValue>
                ) : (
                  <>
                    {allowableDebtTokens?.map((token, i) => (
                      <AllowableTokenLogo token={token} key={token.address} />
                    ))}
                  </>
                )}
              </TokenValue>
            </>
          ) : null}
        </TokenData>
        <ChartWrapper>
          {chartDataType === ChartTypes.VolumeDaily ? (
            <BarChart
              data={(chartUnit as ChartUnit) === ChartUnit.USD ? volumeDataUSD : volumeData}
              loading={loading}
              height={chartHeight}
              minHeight={chartHeight}
              color={theme.blue1}
              setValue={setMainChartValue}
              setLabel={setMainChartLabel}
              defaultValue={currentMainChartValue}
              value={mainChartValue}
              defaultLabel={currentMainChartLabel}
              label={mainChartLabel}
              topRight={
                <RowWrapper>
                  <RowWrapperHideOnSmall>
                    <RowFixed
                      style={{
                        marginLeft: '-40px',
                        marginTop: '8px',
                      }}
                      mobile={true}
                    >
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.SupplyLiquidity}
                        onClick={() => changeChart(ChartTypes.SupplyLiquidity)}
                      >
                        Supply
                      </ChartButton>
                      {!borrowingDisabled && (
                        <ChartButton
                          active={(chartDataType as ChartTypes) === ChartTypes.BorrowLiquidity}
                          onClick={() => changeChart(ChartTypes.BorrowLiquidity)}
                        >
                          Borrow
                        </ChartButton>
                      )}
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.VolumeDaily}
                        onClick={() => changeChart(ChartTypes.VolumeDaily)}
                      >
                        Volume
                      </ChartButton>
                    </RowFixed>
                  </RowWrapperHideOnSmall>
                  <RowWrapper>
                    <RowFixed
                      style={{
                        marginLeft: '-40px',
                        marginTop: '2px',
                        float: 'right',
                      }}
                      mobile={true}
                    >
                      <ChartButton
                        active={(chartUnit as ChartUnit) === ChartUnit.USD}
                        onClick={() => changeChart(ChartUnit.USD, true)}
                      >
                        USD
                      </ChartButton>
                      <ChartButton
                        active={(chartUnit as ChartUnit) === ChartUnit.TOKEN}
                        onClick={() => changeChart(ChartUnit.TOKEN, true)}
                      >
                        Tokens
                      </ChartButton>
                    </RowFixed>
                  </RowWrapper>
                </RowWrapper>
              }
              topLeft={
                <RowWrapper>
                  <RowWrapperHideOnLarge>
                    <RowFixed
                      style={{
                        marginLeft: '-5px',
                        marginTop: '2px',
                      }}
                      mobile={true}
                    >
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.SupplyLiquidity}
                        onClick={() => changeChart(ChartTypes.SupplyLiquidity)}
                      >
                        Supply
                      </ChartButton>
                      {!borrowingDisabled && (
                        <ChartButton
                          active={(chartDataType as ChartTypes) === ChartTypes.BorrowLiquidity}
                          onClick={() => changeChart(ChartTypes.BorrowLiquidity)}
                        >
                          Borrow
                        </ChartButton>
                      )}
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.VolumeDaily}
                        onClick={() => changeChart(ChartTypes.VolumeDaily)}
                      >
                        Volume
                      </ChartButton>
                    </RowFixed>
                  </RowWrapperHideOnLarge>
                  <ChartLabel>
                    <ChartTextLarge small>
                      <StatLoader
                        data={
                          <ChartValue>
                            {formatAmount(
                              tryParseAmountWithNoCurrency((mainChartValue ?? currentMainChartValue).toString()),
                              2,
                              true,
                              (chartUnit as ChartUnit) === ChartUnit.USD ? '$0.00' : '0.00',
                              (chartUnit as ChartUnit) === ChartUnit.USD,
                            )}{' '}
                            {(chartUnit as ChartUnit) !== ChartUnit.USD && symbol}
                          </ChartValue>
                        }
                        loading={loading}
                        x={100}
                      />
                    </ChartTextLarge>
                    <ChartText>
                      {mainChartLabel ?? currentMainChartLabel ? (
                        <ChartValue>{mainChartLabel ?? currentMainChartLabel}</ChartValue>
                      ) : null}
                    </ChartText>
                  </ChartLabel>
                </RowWrapper>
              }
            />
          ) : (
            <LineChart
              data={liquidityData}
              loading={loading}
              height={chartHeight}
              minHeight={chartHeight}
              color={theme.blue1}
              value={mainChartValue}
              label={mainChartLabel}
              setValue={setMainChartValue}
              setLabel={setMainChartLabel}
              topRight={
                <RowWrapper>
                  <RowWrapperHideOnSmall>
                    <RowFixed
                      style={{
                        marginLeft: '-40px',
                        marginTop: '8px',
                      }}
                      mobile={true}
                    >
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.SupplyLiquidity}
                        onClick={() => changeChart(ChartTypes.SupplyLiquidity)}
                      >
                        Supply
                      </ChartButton>
                      {!borrowingDisabled && (
                        <ChartButton
                          active={(chartDataType as ChartTypes) === ChartTypes.BorrowLiquidity}
                          onClick={() => changeChart(ChartTypes.BorrowLiquidity)}
                        >
                          Borrow
                        </ChartButton>
                      )}
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.VolumeDaily}
                        onClick={() => changeChart(ChartTypes.VolumeDaily)}
                      >
                        Volume
                      </ChartButton>
                    </RowFixed>
                  </RowWrapperHideOnSmall>
                  <RowWrapper>
                    <RowFixed
                      style={{
                        marginLeft: '-40px',
                        marginTop: '2px',
                        float: 'right',
                      }}
                      mobile={true}
                    >
                      <ChartButton
                        active={(chartUnit as ChartUnit) === ChartUnit.USD}
                        onClick={() => changeChart(ChartUnit.USD, true)}
                      >
                        USD
                      </ChartButton>
                      <ChartButton
                        active={(chartUnit as ChartUnit) === ChartUnit.TOKEN}
                        onClick={() => changeChart(ChartUnit.TOKEN, true)}
                      >
                        Tokens
                      </ChartButton>
                    </RowFixed>
                  </RowWrapper>
                </RowWrapper>
              }
              topLeft={
                <RowWrapper>
                  <RowWrapperHideOnLarge>
                    <RowFixed
                      style={{
                        marginLeft: '-5px',
                        marginTop: '2px',
                      }}
                      mobile={true}
                    >
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.SupplyLiquidity}
                        onClick={() => changeChart(ChartTypes.SupplyLiquidity)}
                      >
                        Supply
                      </ChartButton>
                      {!borrowingDisabled && (
                        <ChartButton
                          active={(chartDataType as ChartTypes) === ChartTypes.BorrowLiquidity}
                          onClick={() => changeChart(ChartTypes.BorrowLiquidity)}
                        >
                          Borrow
                        </ChartButton>
                      )}
                      <ChartButton
                        active={(chartDataType as ChartTypes) === ChartTypes.VolumeDaily}
                        onClick={() => changeChart(ChartTypes.VolumeDaily)}
                      >
                        Volume
                      </ChartButton>
                    </RowFixed>
                  </RowWrapperHideOnLarge>
                  <ChartLabel>
                    <ChartTextLarge small>
                      <StatLoader
                        data={
                          <ChartValue>
                            {(chartUnit as ChartUnit) === ChartUnit.USD
                              ? formatAmount(
                                  tryParseAmountWithNoCurrency((mainChartValue ?? currentMainChartValue).toString()),
                                  2,
                                  true,
                                  '$0.00',
                                  true,
                                )
                              : `${formatAmount(
                                  tryParseAmountWithNoCurrency((mainChartValue ?? currentMainChartValue).toString()),
                                  2,
                                  true,
                                  '0.00',
                                )} ${symbol}`}
                          </ChartValue>
                        }
                        loading={loading}
                        x={100}
                      />
                    </ChartTextLarge>
                    <ChartText>
                      {mainChartLabel ?? currentMainChartLabel ? (
                        <ChartValue>{mainChartLabel ?? currentMainChartLabel}</ChartValue>
                      ) : null}
                    </ChartText>
                  </ChartLabel>
                </RowWrapper>
              }
            />
          )}
        </ChartWrapper>
      </TokenDataRow>
      <TokenDataRow>
        <ChartWrapper>
          <LineChart
            data={loadedInterestRateData ?? []}
            loading={loading}
            height={chartHeightBottom}
            minHeight={chartHeightBottom}
            color={theme.blue1}
            defaultValue={currentInterestValue}
            value={interestValue}
            defaultLabel={currentInterestLabel}
            label={interestLabel}
            customAxis={interestChartType === InterestChartType.Utilization ? '%' : undefined}
            reference={
              interestChartType === InterestChartType.Utilization
                ? parseFloat(utilizationDisplay.toFixed(2))
                : undefined
            }
            setValue={value => {
              if (typeof value === 'undefined') {
                return
              } else if (value === currentInterestValue) {
                setInterestValue(currentInterestValue)
                return
              }
              const percent = new Percent(Math.floor(value * 100), 10_000)
              setInterestValue(showYieldAsApr ? value : parseFloat(calculateAprToApy(percent).toFixed(2)))
            }}
            setLabel={value => {
              if (interestChartType === InterestChartType.Utilization) {
                setInterestLabel(`${value} ${t('utilizationRate')}`)
              } else {
                setInterestLabel(value)
              }
            }}
            topRight={
              <>
                {borrowingDisabled ? (
                  <ChartRow mobile={true}>
                    <ChartButton
                      active={(interestChartType as InterestChartType) === InterestChartType.Price}
                      onClick={() => changeInterestChart(InterestChartType.Price)}
                      large
                    >
                      Price
                    </ChartButton>
                    <ChartButton
                      active={(interestChartType as InterestChartType) === InterestChartType.Supply}
                      onClick={() => changeInterestChart(InterestChartType.Supply)}
                      large
                    >
                      Supply % History
                    </ChartButton>
                  </ChartRow>
                ) : (
                  <RowWrapperHideOnSmall>
                    <ChartRow mobile={true}>
                      <ChartButton
                        active={(interestChartType as InterestChartType) === InterestChartType.Price}
                        onClick={() => changeInterestChart(InterestChartType.Price)}
                        large
                      >
                        Price
                      </ChartButton>
                      <ChartButton
                        active={(interestChartType as InterestChartType) === InterestChartType.Utilization}
                        onClick={() => changeInterestChart(InterestChartType.Utilization)}
                        large
                      >
                        Utilization
                      </ChartButton>
                      <ChartButton
                        active={(interestChartType as InterestChartType) === InterestChartType.Supply}
                        onClick={() => changeInterestChart(InterestChartType.Supply)}
                        large
                      >
                        Supply % History
                      </ChartButton>
                      <ChartButton
                        active={(interestChartType as InterestChartType) === InterestChartType.Borrow}
                        onClick={() => changeInterestChart(InterestChartType.Borrow)}
                        large
                      >
                        Borrow % History
                      </ChartButton>
                    </ChartRow>
                  </RowWrapperHideOnSmall>
                )}
              </>
            }
            topLeft={
              <ChartLabel>
                {!borrowingDisabled && (
                  <RowWrapperHideOnLarge>
                    <ChartRowMobile>
                      <ChartButtonSmall
                        active={(interestChartType as InterestChartType) === InterestChartType.Price}
                        onClick={() => changeInterestChart(InterestChartType.Price)}
                        large
                      >
                        Price
                      </ChartButtonSmall>
                      <ChartButtonSmall
                        active={(interestChartType as InterestChartType) === InterestChartType.Utilization}
                        onClick={() => changeInterestChart(InterestChartType.Utilization)}
                        large
                      >
                        Util.
                      </ChartButtonSmall>
                      <ChartButtonSmall
                        active={(interestChartType as InterestChartType) === InterestChartType.Supply}
                        onClick={() => changeInterestChart(InterestChartType.Supply)}
                        large
                      >
                        Supply %
                      </ChartButtonSmall>
                      <ChartButtonSmall
                        active={(interestChartType as InterestChartType) === InterestChartType.Borrow}
                        onClick={() => changeInterestChart(InterestChartType.Borrow)}
                        large
                      >
                        Borrow %
                      </ChartButtonSmall>
                    </ChartRowMobile>
                  </RowWrapperHideOnLarge>
                )}
                <>
                  {interestChartType === InterestChartType.Utilization
                    ? 'Interest Rate Model'
                    : interestChartType === InterestChartType.Price
                    ? 'Price History'
                    : interestChartType === InterestChartType.Supply
                    ? 'Supply Interest History'
                    : 'Borrow Interest History'}
                </>
                <ChartTextLarge small>
                  <StatLoader
                    data={
                      interestChartType === InterestChartType.Price ? (
                        <ChartValue>
                          {formatAmount(
                            tryParseAmountWithNoCurrency((interestValue ?? currentInterestValue).toString()),
                            2,
                            true,
                            '$0.00',
                            true,
                          )}
                        </ChartValue>
                      ) : (
                        <ChartValue>
                          {interestValue ?? currentInterestValue ?? 0}% {showYieldAsApr ? t('APR') : t('APY')}
                        </ChartValue>
                      )
                    }
                    loading={loading}
                    x={100}
                  />
                </ChartTextLarge>
                <ChartText>
                  {interestLabel ?? currentInterestLabel ? (
                    <ChartValue>{interestLabel ?? currentInterestLabel}</ChartValue>
                  ) : null}
                </ChartText>
              </ChartLabel>
            }
          />
        </ChartWrapper>
        <TokenData height={bottomHeight}>
          <TokenTitle>{t('liquidationThreshold')}</TokenTitle>
          <StatLoader
            data={
              <TokenValue>{formatAmount(liquidationThreshold, 2, true, '-', false, Rounding.ROUND_HALF_UP)}</TokenValue>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={42}
          />
          <TokenTitle>{t('liquidationPenalty')}</TokenTitle>
          <StatLoader
            data={
              <TokenValue>{formatAmount(liquidationReward, 2, true, '-', false, Rounding.ROUND_HALF_UP)}</TokenValue>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={42}
          />
          {marketRiskInfo?.supplyMaxWei ? (
            <>
              <TokenTitle>{t('supplyCap')}</TokenTitle>
              <StatLoader
                data={<TokenValue>{`${marketRiskInfo.supplyMaxWei.toFixed(2, FORMATTER)} ${symbol}`}</TokenValue>}
                loading={loading}
                x={100}
                y={22}
                top={6}
                height={42}
              />
            </>
          ) : null}
          {borrowCap && (
            <>
              <TokenTitle>{t('borrowCap')}</TokenTitle>
              <StatLoader
                data={<TokenValue>{`${formatAmount(borrowCap)} ${symbol}`}</TokenValue>}
                loading={loading}
                x={100}
                y={22}
                top={6}
                height={42}
              />
            </>
          )}
          {!borrowingDisabled && protocalBalance && (
            <>
              <TokenTitle>{t('availableToWithdraw')}</TokenTitle>
              <StatLoader
                data={<TokenValue>{`${formatAmount(protocalBalance)} ${symbol}`}</TokenValue>}
                loading={loading}
                x={100}
                y={22}
                top={6}
                height={42}
              />
            </>
          )}
          <LinkWrapper>
            <ExternalLink
              style={{
                marginTop: '8px',
                flexBasis: '100%',
                textAlign: 'center',
                fontSize: '14px',
                textDecoration: 'none',
              }}
              href={getEtherscanLink(selectedChain, oracleAddress, 'address')}
            >
              {t('oracleContract')}
              <ExternalLinkIcon
                style={{
                  marginTop: '8px',
                  flexBasis: '100%',
                  textAlign: 'center',
                  fontSize: '14px',
                  textDecoration: 'none',
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  marginLeft: '-5px',
                  marginBottom: '-1px',
                }}
                href={getEtherscanLink(selectedChain, oracleAddress, 'address')}
              />
            </ExternalLink>
          </LinkWrapper>
        </TokenData>
      </TokenDataRow>
      <StyledTabs
        value={selectedTab}
        onChange={(_: any, index: number) => setSelectedTab(index)}
        indicatorColor={'primary'}
        textColor={'primary'}
      >
        {(options || []).map((option: string, index: number) => (
          <StyledTab key={`tokenActions-${index}`} disableRipple label={option} onClick={() => setSelectedTab(index)} />
        ))}
      </StyledTabs>
      <Trades>
        {selectedTab === TRADING ? (
          <TradeTable trades={trades} isLoading={isTradesLoading} />
        ) : selectedTab === TRANSFERS ? (
          <DepositTable transfers={transferData} isLoading={isTransfersLoading} />
        ) : (
          <Liquidations liquidations={liquidationData} isLoading={isLiquidationsLoading} />
        )}
      </Trades>
    </TokenStatsWrapper>
  )
}
