import { Currency, Trade, TradeType } from '@dolomite-exchange/v2-sdk'
import React, { Fragment, memo, useContext } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex } from 'rebass'
import { ThemeContext } from 'styled-components/macro'
import { TYPE } from '../../theme'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'

export default memo(function SwapRoute({ trade }: { trade: Trade<Currency, Currency, TradeType> }) {
  const theme = useContext(ThemeContext)
  return (
    <Flex flexWrap='wrap' width='100%' justifyContent='flex-end' alignItems='center'>
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        return (
          <Fragment key={i}>
            <Flex alignItems='end'>
              <TYPE.black fontSize={12} color={theme.text1} ml='0.125rem' mr='0.125rem'>
                {cleanCurrencySymbol(token)}
              </TYPE.black>
            </Flex>
            {isLastItem ? null : <ChevronRight size={11} color={theme.text2} />}
          </Fragment>
        )
      })}
    </Flex>
  )
})
