import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import BalancesPanel from '../../components/Balances/BalancesPanel'
import BalancesTotal from '../../components/Balances/BalancesTotal'
import FirstVisitPopoverBalances from '../../components/FirstVisitPopoverBalances/FirstVisitPopoverBalances'
import useApprovedToS from '../../hooks/useApprovedToS'
import { useActiveWeb3React } from '../../hooks'
import BeraBearImg from '../../assets/images/Bera-Bear.svg'
import { isBerachain } from '../../constants/chainId'
import BorrowPositionsCarousel from '../../components/Balances/BorrowPositionsCarousel'
import BalancesTour from '../../components/BalancesTour/BalancesTour'

// TODO - thoughts on adding a pie chart in the lower left (update - actually put it in the main balances panel in the upper left, with the text currently at the top moved to the right and aligned left) showing a breakdown of what percentage deposited is what
//        asset? Or do you think most users will just lend a single asset? Or would having that encourage them to
//        diversify?

// TODO - handle extra whitespace at bottom of the page that leads to unnecessary scrolling

const PageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`
const BalancesWrapper = styled.div`
  margin: 25px auto 0;
  max-width: 1400px;
  text-align: center;
  width: calc(100% - 62px);
  @media screen and (max-width: 1500px) {
    max-width: 1250px;
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 50px);
  }

  @media screen and (max-width: 919px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 619px) {
    width: calc(100% - 32px);
  }

  @media screen and (max-width: 450px) {
    width: calc(100% - 18px);
  }

  @media screen and (max-width: 360px) {
    width: calc(100% - 18px);
  }

  @media screen and (min-width: 5000px) {
    margin-top: 0;
  }
`

const TopSection = styled.div`
  vertical-align: top;
  width: 100%;
  height: 190px;
  //max-width: 900px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;

  @media screen and (max-width: 1280px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 1000px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
  }

  @media screen and (max-width: 650px) {
    margin-top: 0;
  }
`

const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.text2};
  display: inline-block;
  vertical-align: top;
`

const TotalWrapper = styled.div<{ closeInfo: boolean }>`
  vertical-align: top;
  width: ${({ closeInfo }) => (closeInfo ? 100 : 50)}%;
  display: inline-block;

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`

const BeraBear = styled.img<{ tutorialVisible: boolean }>`
    position: fixed;
    width: 18%;
    left: -2%;
    bottom: 45px;
    z-index: -1;

    @media screen and (max-width: 1400px) {
        display: none;
            /*${({ tutorialVisible }) => tutorialVisible && `display: none;`}
    position: absolute;
    width: 200px;
    right: calc(50% - 406px);
    left: auto;
    bottom: auto;
    top: 330px;
    transform: scale(-1, 1);*/
    }

    @media screen and (max-width: 900px) {
        right: 40px;
    }

    @media screen and (max-width: 750px) {
        top: 275px;
    }

    @media screen and (max-width: 500px) {
        width: 150px;
    }
`

const MenuDiv = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};

  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  > svg {
    margin-right: 8px;
  }
`

export default function Balances() {
  const { chainId } = useActiveWeb3React()
  const [hasVisitedBalances, setHasVisitedBalances] = useState(true)
  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()
  const [closeInfo, setCloseInfo] = useState(true) // useCloseBalancesInfo()
  const [tour, setTour] = useState(false)
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = useCallback(
    () =>
      setSize({
        x: window.innerWidth,
        y: window.innerHeight,
      }),
    [],
  )

  useEffect(() => {
    window.onresize = updateSize
    const localStorageContents = window.localStorage.getItem('dolomite_has_visited_balances')
    if (!localStorageContents || localStorageContents !== 'true') {
      setHasVisitedBalances(false)
    }
    return () => {
      window.onresize = null
    }
  }, [updateSize])

  const setHasVisited = useCallback(() => {
    window.localStorage.setItem('dolomite_has_visited_balances', 'true')
    setHasVisitedBalances(true)
  }, [])

  return (
    <PageWrapper>
      <PageTitle title={isBerachain(chainId) ? 'Bolomite | Balances' : 'Dolomite | Balances'} />
      {(!hasVisitedBalances || !hasApprovedToS) && (
        <FirstVisitPopoverBalances
          width={size.x}
          height={size.y}
          approvedToS={hasApprovedToS}
          setHasVisited={setHasVisited}
          setApprovedToS={setHasApprovedToS}
        />
      )}
      {isBerachain(chainId) && <BeraBear src={BeraBearImg} tutorialVisible={!closeInfo} />}
      <BalancesWrapper>
        {/* TODO - make News discoverable and show a little number icon when a user hasn't seen the latest news */}
        {/*<News />*/}
        <TopSection>
          <BalancesTotal />
          <VerticalLine />
          <BorrowPositionsCarousel />
        </TopSection>
        {/*<News />*/}
        <BalancesPanel />
      </BalancesWrapper>
      {tour && size.x > 1000 && (
        <BalancesTour
          width={size.x}
          height={size.y}
          approvedToS={true}
          setHasVisited={() => setHasVisitedBalances}
          setApprovedToS={() => setHasApprovedToS}
        />
      )}
    </PageWrapper>
  )
}
