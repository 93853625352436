import { Contract, ContractInterface } from '@ethersproject/contracts'
import BORROW_POSITION_PROXY_V1 from '@dolomite-exchange/dolomite-margin/build/contracts/IBorrowPositionProxyV1.json'
import DOLOMITE_MARGIN_PROTOCOL from '@dolomite-exchange/dolomite-margin/build/contracts/DolomiteMargin.json'
import TEST_TOKEN from '@dolomite-exchange/dolomite-margin/build/contracts/TestToken.json'

import GOVERNANCE from '@uniswap/governance/build/GovernorAlpha.json'
import MERKLE_DISTRIBUTOR from '@uniswap/merkle-distributor/build/MerkleDistributor.json'
import STAKING_REWARDS from '@uniswap/liquidity-staker/build/StakingRewards.json'
import UNI_JSON from '@uniswap/governance/build/Uni.json'

import CAMELOT_AMM_PAIR from '../constants/abis/camelot-amm-pair.json'
import D_ARB_USER_VAULT from '../constants/abis/arb-wrapped-token-user-vault-v1.json'
import D_GMX_USER_VAULT from '../constants/abis/gmx-wrapped-token-user-vault-v1.json'
import DFS_GLP from '../constants/abis/glp-wrapped-token-user-vault-factory.json'
import DFS_GLP_USER_VAULT from '../constants/abis/glp-wrapped-token-user-vault-v1.json'
import DJ_USDC_USER_VAULT from '../constants/abis/jones-usdc-wrapped-token-user-vault-v2.json'
import DPLV_GLP_USER_VAULT from '../constants/abis/plv-glp-user-vault.json'
import D_WMNT_USER_VAULT from '../constants/abis/wmnt-token-user-vault-v1.json'
import DYT_GLP_USER_VAULT from '../constants/abis/yt-glp-user-vault.json'
import DOLOMITE_AMM_ROUTER_PROXY from '../constants/abis/dolomite-amm-router-proxy.json'
import DOLOMITE_AMM_PAIR from '../constants/abis/dolomite-amm-pair.json'
import GENERIC_TRADER_PROXY from '../constants/abis/generic-trader-proxy.json'
import GLP_MANAGER from '../constants/abis/glp-manager.json'
import GMX_READER_V2 from '../constants/abis/gmx-reader-v2.json'
import GMX_REWARD_READER from '../constants/abis/gmx-reward-reader.json'
import GMX_REWARD_ROUTER from '../constants/abis/gmx-reward-router.json'
import GMX_REWARD_TRACKER from '../constants/abis/gmx-reward-tracker.json'
import GMX_VESTER from '../constants/abis/gmx-vester.json'
import ISOLATION_MODE_USER_VAULT from '../constants/abis/isolation-mode-user-vault-v1.json'
import ISOLATION_MODE_USER_VAULT_PAYABLE from '../constants/abis/isolation-mode-user-vault-with-payable.json'
import ASYNC_ISOLATION_MODE_USER_VAULT from '../constants/abis/isolation-mode-freezable-token-user-vault.json'
import ISOLATION_MODE_VAULT_FACTORY from '../constants/abis/isolation-mode-token-vault-factory.json'
import JONES_USDC_CHEF from '../constants/abis/jones-usdc-chef.json'
import LIQUIDITY_MINING_CLAIM from '../constants/abis/liquidity-mining-claim.json'
import LIQUIDITY_MINING_VESTER from '../constants/abis/liquidity-mining-vester.json'
import MULTICALL from '../constants/abis/multicall-abi.json'
import PENDLE_YT_TOKEN from '../constants/abis/pendle-yt-token.json'
import PLUTUS_CHEF from '../constants/abis/plutus-chef.json'
import USDM_ROUTER from '../constants/abis/usdm-router.json'

import { WRAPPED_CURRENCY } from '@dolomite-exchange/v2-sdk'
import { useMemo } from 'react'
import {
  BN_GMX_ADDRESSES,
  BN_GMX_REWARD_TRACKER_ADDRESSES,
  BORROW_POSITION_PROXY_V1_ADDRESSES,
  ChainId,
  DEPOSIT_WITHDRAWAL_PROXY_ADDRESSES,
  DEPOSIT_WITHDRAWAL_ROUTER_ADDRESSES,
  DOLOMITE_AMM_ROUTER_ADDRESSES,
  DOLOMITE_MARGIN_PROTOCOL_ADDRESSES,
  ES_GMX_ADDRESSES,
  EXTENDED_GMX_FEE_TRACKER_ADDRESSES,
  EXTENDED_GMX_TRACKER_ADDRESSES,
  FEE_GLP_REWARD_TRACKER_ADDRESSES,
  FEE_GMX_REWARD_TRACKER_ADDRESSES,
  FS_GLP_ISOLATION_MODE_ADDRESSES,
  GENERIC_TRADER_PROXY_ADDRESSES,
  GLP_ADDRESSES,
  GLP_MANAGER_ADDRESSES,
  GLP_VESTER_ADDRESSES,
  GMX_ADDRESSES,
  GMX_READER_V2_ADDRESSES,
  GMX_REWARD_READER_ADDRESSES,
  GMX_REWARD_ROUTER_ADDRESSES,
  GMX_VESTER_ADDRESSES,
  GOVERNANCE_ADDRESS,
  JONES_USDC_FARM_ADDRESSES,
  LIQUIDITY_MINING_O_TOKEN_VESTER_ADDRESSES,
  MERKLE_DISTRIBUTOR_ADDRESS,
  MULTICALL_ADDRESSES,
  PLV_GLP_FARM_ADDRESSES,
  STAKED_GLP_REWARD_TRACKER_ADDRESSES,
  STAKED_GMX_REWARD_TRACKER_ADDRESSES,
  UNI,
  USDM_ROUTER_ADDRESSES,
} from '../constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS,
} from '../constants/abis/argent-wallet-detector'
import DEPOSIT_WITHDRAWAL_PROXY from '../constants/abis/deposit-withdrawal-proxy.json'
import DEPOSIT_WITHDRAWAL_ROUTER from '../constants/abis/deposit-withdrawal-router.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import { MINERALS_AIRDROP_ABI, MINERALS_AIRDROP_ADDRESS } from '../constants'

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: ContractInterface,
  withSignerIfPossible = true,
): Contract | null {
  const addresses = useMemo(() => [address], [address])
  return useContracts(addresses, ABI, withSignerIfPossible)[0] ?? null
}

function useContracts(
  addresses: (string | undefined)[],
  ABI: ContractInterface,
  withSignerIfPossible = true,
): (Contract | null)[] {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    return addresses.map(address => {
      if (!address) {
        return null
      }
      try {
        return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
      } catch (error) {
        console.error('Failed to get contract', error)
        return null
      }
    })
  }, [addresses, ABI, library, withSignerIfPossible, account])
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false,
  )
}

export function useBnGmxContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(BN_GMX_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useBnGmxTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(BN_GMX_REWARD_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useBorrowPositionProxyV1Contract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(BORROW_POSITION_PROXY_V1_ADDRESSES[chainId], BORROW_POSITION_PROXY_V1.abi)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useCamelotPairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, CAMELOT_AMM_PAIR.abi, withSignerIfPossible)
}

export function useDepositWithdrawalProxyContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(DEPOSIT_WITHDRAWAL_PROXY_ADDRESSES[chainId], DEPOSIT_WITHDRAWAL_PROXY.abi)
}

export function useDepositWithdrawalRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(DEPOSIT_WITHDRAWAL_ROUTER_ADDRESSES[chainId], DEPOSIT_WITHDRAWAL_ROUTER.abi)
}

export function useDArbUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, D_ARB_USER_VAULT.abi)
}

export function useDfsGlpContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(FS_GLP_ISOLATION_MODE_ADDRESSES[chainId], DFS_GLP.abi)
}

export function useDfsGlpUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, DFS_GLP_USER_VAULT.abi)
}

export function useDGmxUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, D_GMX_USER_VAULT.abi)
}

export function useDjUSDCUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, DJ_USDC_USER_VAULT.abi)
}

export function useDplvGlpUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, DPLV_GLP_USER_VAULT.abi)
}

export function useDWmntUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, D_WMNT_USER_VAULT.abi)
}

export function useDytGlpUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, DYT_GLP_USER_VAULT.abi)
}

export function useDolomiteAmmRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(DOLOMITE_AMM_ROUTER_ADDRESSES[chainId], DOLOMITE_AMM_ROUTER_PROXY.abi)
}

export function useDolomiteMarginContract(urlChain?: ChainId): Contract | null {
  const { chainId } = useActiveWeb3React()
  const chain = useMemo(() => urlChain ?? chainId, [chainId, urlChain])
  return useContract(DOLOMITE_MARGIN_PROTOCOL_ADDRESSES[chain], DOLOMITE_MARGIN_PROTOCOL.abi)
}

export function useENSRegistrarContract(chainId: ChainId, withSignerIfPossible?: boolean): Contract | null {
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useENSResolverContracts(addresses: string[], withSignerIfPossible?: boolean): (Contract | null)[] {
  return useContracts(addresses, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useEsGmxContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(ES_GMX_ADDRESSES[chainId], ERC20_ABI)
}

export function useExtendedGmxFeeTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(EXTENDED_GMX_FEE_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useExtendedGmxTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(EXTENDED_GMX_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useFeeGlpTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(FEE_GLP_REWARD_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useFeeGmxTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(FEE_GMX_REWARD_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useGenericTraderProxyContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GENERIC_TRADER_PROXY_ADDRESSES[chainId], GENERIC_TRADER_PROXY.abi)
}

export function useGlpContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GLP_ADDRESSES[chainId], ERC20_ABI)
}

export function useGlpManagerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GLP_MANAGER_ADDRESSES[chainId], GLP_MANAGER.abi)
}

export function useGlpVesterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GLP_VESTER_ADDRESSES[chainId], GMX_VESTER.abi)
}

export function useGmxContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GMX_ADDRESSES[chainId], ERC20_ABI)
}

export function useGmxReaderV2Contract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GMX_READER_V2_ADDRESSES[chainId], GMX_READER_V2.abi)
}

export function useGmxRewardReaderContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GMX_REWARD_READER_ADDRESSES[chainId], GMX_REWARD_READER.abi)
}

export function useGmxRewardRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GMX_REWARD_ROUTER_ADDRESSES[chainId], GMX_REWARD_ROUTER.abi, true)
}

export function useGmxVesterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GMX_VESTER_ADDRESSES[chainId], GMX_VESTER.abi)
}

export function useGovernanceContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(GOVERNANCE_ADDRESS[chainId], GOVERNANCE.abi, true)
}

export function useIsolationModeUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, ISOLATION_MODE_USER_VAULT.abi)
}

export function useIsolationModeUserVaultPayableContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, ISOLATION_MODE_USER_VAULT_PAYABLE.abi)
}

export function useAsyncIsolationModeUserVaultContract(vaultAddress?: string): Contract | null {
  return useContract(vaultAddress, ASYNC_ISOLATION_MODE_USER_VAULT.abi)
}

export function useIsolationModeVaultFactoryContract(address?: string): Contract | null {
  return useContract(address, ISOLATION_MODE_VAULT_FACTORY.abi)
}

export function useJusdcChefContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(JONES_USDC_FARM_ADDRESSES[chainId], JONES_USDC_CHEF.abi)
}

export function useLiquidityMiningClaimContract(distributorAddress: string | undefined): Contract | null {
  return useContract(distributorAddress, LIQUIDITY_MINING_CLAIM.abi, true)
}

export function useLiquidityMiningVesterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(LIQUIDITY_MINING_O_TOKEN_VESTER_ADDRESSES[chainId], LIQUIDITY_MINING_VESTER.abi, true)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR.abi, true)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(MULTICALL_ADDRESSES[chainId], MULTICALL.abi, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, DOLOMITE_AMM_PAIR.abi, withSignerIfPossible)
}

export function usePendleYtToken(ytToken?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(ytToken, PENDLE_YT_TOKEN.abi, withSignerIfPossible)
}

export function usePlutusChefContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(PLV_GLP_FARM_ADDRESSES[chainId], PLUTUS_CHEF.abi)
}

export function useSocksControllerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    (UNISOCKS_ABI as any) as ContractInterface,
    false,
  )
}

export function useStakedGlpTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(STAKED_GLP_REWARD_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useStakedGmxTrackerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(STAKED_GMX_REWARD_TRACKER_ADDRESSES[chainId], GMX_REWARD_TRACKER.abi)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS.abi, withSignerIfPossible)
}

export function useTestTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, TEST_TOKEN.abi, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useUniContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(UNI[chainId]?.address, UNI_JSON.abi, true)
}

export function useUsdmRouterContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(USDM_ROUTER_ADDRESSES[chainId], USDM_ROUTER.abi, true)
}

export function useWrappedCurrencyContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(WRAPPED_CURRENCY[chainId].address, WETH_ABI, withSignerIfPossible)
}

export function useMineralsAirdropContract() {
  const { chainId } = useActiveWeb3React()
  const address = chainId ? MINERALS_AIRDROP_ADDRESS[chainId] : undefined
  return useContract(address, MINERALS_AIRDROP_ABI)
}
